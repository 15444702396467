import React from "react";

import colorfulStars from "../../assets/images/Pages/Home/colorfulStars.svg";
import quotes from "../../assets/images/Pages/Home/quote.svg";

export default function Feedback({ comment, pathToImage, name }) {
  return (
    <div className="opinion-box px-3 pt-5 pb-4 bg-white shadow-sm position-relative">
      <div className="quotes position-absolute">
        <img src={quotes} alt="" />
      </div>
      <div className="stars mb-3">
        <img src={colorfulStars} alt="" />
      </div>
      <p className="opinion">{comment}</p>
      <div className="personal-image-name d-flex align-items-center w-100 pt-3 gap-3">
        <div className="personal-image">
          <img src={pathToImage} alt="sss" />
        </div>
        <div className="personal-name mb-0">
          <p className="mb-0">{name}</p>
        </div>
      </div>
    </div>
  );
}
