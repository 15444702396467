import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import CHGridAr from "../Common/CHGridAr";

export default function ServicesAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="servicesPage">
          <section
            className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden"
            dir="rtl"
          >
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <p className="mb-2">علينا أن نبحث</p>
              <h1 className="text-uppercase">ماذا تحتاج</h1>
            </div>
          </section>
          <section
            className="services-discription text-center w-100 d-flex align-items mx-auto-center flex-column"
            dir="rtl"
          >
            <div className="services-discription-content mx-auto">
              <p className="fw-medium mb-2">
                نحن نعلم الجهد الذي يتطلبه تحقيق الحلم و أهمية العناية بتراثك،
                لهذا السبب
              </p>
              <p className="fw-bold">
                نقوم بتصميم التأمين الذي يركز على احتياجاتك.
              </p>
            </div>
          </section>
          <section className="services-blocks d-flex flex-wrap" dir="rtl">
            <div className="services-block services-block1 rounded rounded-4 p-4">
              <div className="service-block-content d-flex flex-column justify-content-end align-items-center h-100 text-white position-relative text-center p-3">
                <h3 className="fw-bold mb-3">تأمين الشركات</h3>
                <p>
                  تأمين الأعمال يحمي مصالحك ويضمن ذلك كل شيء سوف يستمر في العمل،
                  حتى لو كان هناك غير متوقع.
                </p>
                <NavLink
                  className="nav-link text-capitalize text-white pink-Nav-link d-inline-block px-4 py-2 rounded rounded-3"
                  to="corporateAr"
                >
                  اقرأ المزيد
                </NavLink>
              </div>
            </div>
            <div className="services-block services-block2 rounded rounded-4 p-4">
              <div className="service-block-content d-flex flex-column justify-content-end align-items-center h-100 text-white position-relative text-center p-3">
                <h3 className="fw-bold mb-3">المخاطر المهنية</h3>
                <p>
                  نحن نركز على الدعم الشامل وتقديم المشورة للشركات حول القضايا
                  المتعلقة بالأمن والصحة في العمل.
                </p>
                <NavLink
                  className="nav-link text-capitalize text-white pink-Nav-link d-inline-block px-4 py-2 rounded rounded-3"
                  to="occupationalAr"
                >
                  اقرأ المزيد
                </NavLink>
              </div>
            </div>
            <div className="services-block services-block3 rounded rounded-4 p-4">
              <div className="service-block-content d-flex flex-column justify-content-end align-items-center h-100 text-white position-relative text-center p-3">
                <h3 className="fw-bold mb-3">نصيحة بشأن إدارة المخاطر</h3>
                <p>نهجنا هو تحديد وتقييم وإدارة كل المخاطر في طريقة عالمية.</p>
                <NavLink
                  className="nav-link text-capitalize text-white pink-Nav-link d-inline-block px-4 py-2 rounded rounded-3"
                  to="managementAr"
                >
                  اقرأ المزيد
                </NavLink>
              </div>
            </div>
            <div className="services-block services-block4 rounded rounded-4 p-4">
              <div className="service-block-content d-flex flex-column justify-content-end align-items-center h-100 text-white position-relative text-center p-3">
                <h3 className="fw-bold mb-3">نصيحة بشأن المطالبات</h3>
                <p>نحن نقدم لك مرافقة دائمة في عملية المطالبة.</p>
                <NavLink
                  className="nav-link text-capitalize text-white pink-Nav-link d-inline-block px-4 py-2 rounded rounded-3"
                  to="claimsAr"
                >
                  اقرأ المزيد
                </NavLink>
              </div>
            </div>
          </section>
          <section
            className="purpose d-flex justify-content-center align-items-center flex-column rounded rounded-3 overflow-hidden text-white"
            dir="rtl"
          >
            <div className="purposeContent text-center">
              <h2 className="fw-bolder text-uppercase">غرضنا</h2>
              <p className="w-75 fs-5 text-center m-auto fw-medium">
                تعزيز رفاهية الشركات وضمان مستقبلها حماية.
              </p>
            </div>
          </section>
          <CHGridAr />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              نحن نفعل الأشياء بشكل جيد
            </h2>
            <div className="doingList d-flex ">
              <p>نحن نفكر فيك</p>
              <p>نحن نولد قيمة بالنسبة لك</p>
              <p>نحن نحميك</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contactAr"
            >
              اتصل بنا
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
