import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import serviceimageblock1 from "../../../assets/images/Pages/Services/corporate/images/block1.jpg";
import serviceimageblock2 from "../../../assets/images/Pages/Services/corporate/images/block2.jpg";
import serviceimageblock3 from "../../../assets/images/Pages/Services/corporate/images/block3.jpg";
import serviceimageblock4 from "../../../assets/images/Pages/Services/corporate/images/block4.webp";
import CHGridAr from "../../Common/CHGridAr";

export default function CorporateAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="corporateServicesPage">
          <section
            className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden"
            dir="rtl"
          >
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <p className="mb-2">علينا أن نبحث</p>
              <h1 className="text-uppercase">ماذا تحتاج</h1>
            </div>
          </section>
          <section
            className="service-discription w-100 d-flex align-items mx-auto-center flex-column"
            dir="rtl"
          >
            <div className="service-discription-content mx-auto">
              <p className="fw-medium mb-2">
                تواجه الشركات مثل الأشخاص مخاطر تتطور باستمرار.
                <span className="fw-bold mx-2">
                  التأمين هو أفضل وسيلة لحماية مصالحك وضمانها أن كل شيء سيستمر
                  في العمل، حتى لو كان هناك حدث غير متوقع.
                </span>
              </p>
            </div>
          </section>
          <section
            className="service-block service-block1 d-flex justify-content-between align-items-center flex-wrap"
            dir="rtl"
          >
            <ul className="nav-list service-block-list d-flex flex-column gap-3 fw-bold">
              <li className="nav-item">الحياة الجماعية</li>
              <li className="nav-item">حياة المدين</li>
              <li className="nav-item">جماعية السيارات</li>
              <li className="nav-item">النقل</li>
              <li className="nav-item">نقل الأشياء الثمينة</li>
              <li className="nav-item">الامتثال</li>
              <li className="nav-item">ادارة تجارية</li>
            </ul>
            <div className="service-block-image rounded rounded-4">
              <img src={serviceimageblock1} className="img-fluid" alt="" />
            </div>
          </section>
          <section
            className="service-block service-block2 d-flex justify-content-between align-items-center flex-wrap"
            dir="rtl"
          >
            <div className="service-block-image rounded rounded-4">
              <img src={serviceimageblock2} className="img-fluid" alt="" />
            </div>
            <ul className="nav-list service-block-list d-flex flex-column gap-3 fw-bold ">
              <li className="nav-item">جميع مخاطر البناء</li>
              <li className="nav-item">جميع الأضرار المادية المخاطر</li>
              <li className="nav-item">جميع تجميع المخاطر</li>
              <li className="nav-item">المسؤولية المدنية</li>
              <li className="nav-item">عشري</li>
              <li className="nav-item">الملكية المشتركة</li>
            </ul>
          </section>
          <section
            className="service-block service-block3 d-flex justify-content-between align-items-center flex-wrap"
            dir="rtl"
          >
            <ul className="nav-list service-block-list d-flex flex-column gap-3 fw-bold">
              <li className="nav-item">الآلات المتنقلة</li>
              <li className="nav-item">الخوذة والطيران</li>
              <li className="nav-item">اليخوت وقوارب المتعة </li>
              <li className="nav-item">التأمين الزراعي</li>
              <li className="nav-item">بطاقة تامين</li>
              <li className="nav-item">الخطوط المالية</li>
            </ul>
            <div className="service-block-image rounded rounded-4">
              <img src={serviceimageblock3} className="img-fluid" alt="" />
            </div>
          </section>
          <section
            className="service-block service-block2 d-flex justify-content-between align-items-center flex-wrap"
            dir="rtl"
          >
            <div className="service-block-image rounded rounded-4">
              <img src={serviceimageblock4} className="img-fluid" alt="" />
            </div>
            <ul className="nav-list service-block-list d-flex flex-column gap-3 fw-bold ">
              <li className="nav-item">مديرو ومديرو RC</li>
              <li className="nav-item">الخيانة والمخاطر المالية</li>
              <li className="nav-item">المخاطر السيبرانية</li>
            </ul>
          </section>
          <CHGridAr />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              نحن نفعل الأشياء بشكل جيد
            </h2>
            <div className="doingList d-flex ">
              <p>نحن نفكر فيك</p>
              <p>نحن نولد قيمة بالنسبة لك</p>
              <p>نحن نحميك</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contactAr"
            >
              اتصل بنا
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
