import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import blogsData from "./../Data/BlogsData";

export default function BlogDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();
  const blogdata = blogsData
    .filter((item) => item.id === +params.id)
    .map((item) => item);
  return (
    <>
      <div className="custom-container">
        <div className="blogDetailsPage">
          <section className="mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroImage position-relative">
              <div
                className="imageOverlay w-100 h-100 bg-black z-0 position-absolute top-0 start-0"
                style={{ opacity: 0.3 }}
              ></div>
              <img
                src={`../../../${blogdata[0].imageURL}`}
                className="img-fluid"
                alt="article hero"
              />
            </div>
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h1 className="mb-2">{blogdata[0].mainTitle}</h1>
              <p className="mb-2">{blogdata[0].secondTitle}</p>
            </div>
          </section>
          <section className="blogDetails-description fw-medium">
            <div className="block mb-5">
              <p className="fs-5">{blogdata[0].description}</p>
            </div>
            <div className="block mb-5">
              <h3 className="fw-bold">{blogdata[0].article1Title}</h3>
              <p className="fs-5">{blogdata[0].article1}</p>
            </div>
            <div className="block mb-5">
              <h3 className="fw-bold">{blogdata[0].article2Title}</h3>
              <p className="fs-5">{blogdata[0].article2}</p>
            </div>
            <div className="block mb-5">
              <h3 className="fw-bold">{blogdata[0].article3Title}</h3>
              <p className="fs-5">
                {blogdata[0].article3}
                <p className="fw-bold mt-3">
                  You can contact us through our website or via email:
                  <a href="mailto:Info@gpco.com.sa" className="fw-normal">
                    {" "}
                    Info@gpco.com.sa
                  </a>
                </p>
              </p>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
