import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import patternClaims1 from "../../../assets/images/Pages/Services/claims/images/claims-image1.jpg";
import patternClaims2 from "../../../assets/images/Pages/Services/claims/images/claims-image2.jpg";
import patternClaims3 from "../../../assets/images/Pages/Services/claims/images/claims-image3.jpg";
import CHGridAr from "../../Common/CHGridAr";

export default function ClaimsAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="claimsServicesPage">
          <section
            className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden"
            dir="rtl"
          >
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h1 className="fw-bolder mb-0">نصيحة بشأن المطالبات</h1>
            </div>
          </section>
          <section
            className="service-discription w-100.. d-flex align-items mx-auto-center flex-column"
            dir="rtl"
          >
            <div className="service-discription-content mx-auto">
              <p className="fw-medium mb-2">
                تساعد خدمة المطالبات شركتك على تقديم المطالبات والحصول عليها
                التعويض في حالة حدوث خسائر مالية أو أضرار تلحق بك عمل. تشمل هذه
                الخدمة استلام المطالبات وتقديرها الأضرار، ومعالجتها حتى تحصل على
                حقها تعويض.
              </p>
            </div>
          </section>
          <section
            className="pattern3 d-flex justify-content-between gap-5"
            dir="rtl"
          >
            <div className="pattern-text">
              <ol className="claims-list nav-list fw-medium">
                <li className="nav-item">
                  <p>
                    مطالبات التأمين: للحصول على تعويض مالي في حالة وقوع حوادث أو
                    خسائر تغطيها وثائق التأمين.
                  </p>
                </li>
                <li className="nav-item">
                  <p>
                    مطالبة التعويض: الحصول على تعويض من الآخرين الأطراف في حالة
                    تسبب هذه الأطراف في خسائر أو الضرر الذي يلحق بالشركة.
                  </p>
                </li>
                <li className="nav-item">
                  <p>
                    المطالبات بالتعويض عن الأضرار التجارية في حالة تجارية
                    الأضرار الناجمة عن التأخير في تسليم البضائع أو خدمات.
                  </p>
                </li>
                <li className="nav-item">
                  <p>
                    مطالبات الضمان: إذا كانت المنتجات أو الخدمات المقدمة من قبل
                    الشركة غير مطابقة للمواصفات المتفق عليها مع الشركة يمكن
                    تقديم مطالبات للمطالبة بإصلاح المنتجات أو استبدالها أو تقديم
                    خدمات إضافية.
                  </p>
                </li>
                <li className="nav-item">
                  <p>
                    مطالبات التعويض عن الأضرار القانونية: في بعض الحالات، يمكن
                    للشركات رفع دعاوى قانونية للحصول على تعويض الأضرار التي قد
                    تكون نتيجة للأفعال غير القانونية التي يقوم بها الأطراف
                    الأخرى.
                  </p>
                </li>
              </ol>
            </div>
            <div className="pattern-images">
              <div className="pattern-images-group">
                <img src={patternClaims1} alt="" className="pattern-image1" />
                <img src={patternClaims2} alt="" className="pattern-image2" />
                <img src={patternClaims3} alt="" className="pattern-image3" />
              </div>
            </div>
          </section>
          <section
            className="claims-discription d-flex justify-content-center align-items-center text-white text-center rounded rounded-5"
            dir="rtl"
          >
            <p className="fs-5 z-1 fw-bold">
              خلال 10 سنوات من الخبرة، قدمنا الاستشارات بشأن إدارة العديد من
              الحوادث التي تجاوزت مبالغها الريال 25000 لكل حادثة.
            </p>
          </section>
          <CHGridAr />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              نحن نفعل الأشياء بشكل جيد
            </h2>
            <div className="doingList d-flex ">
              <p>نحن نفكر فيك</p>
              <p>نحن نولد قيمة بالنسبة لك</p>
              <p>نحن نحميك</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contactAr"
            >
              اتصل بنا
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
