export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

export const personsFeedbackDataAr = [
  {
    id: 1,
    comment: `"تعاقدي معاهم كان ايجابي مرة متمزين بالسرعة و يوفروا لك التأمين للي تحتاجه شركتك بالظبط و كثير متعاونين في شرح أنواع تأمين للي تفيد عملك و ماهرين جداً في معالجة المطالبات و تقديمها للجهات المختصة و ما يتركون المطالبة حتى تأخذ حقك راضي عن تجربتي معاهم بشكل عام"`,
    path: "../../../saudimale.svg",
    name: "جاسر الشهراني",
  },
  {
    id: 2,
    comment: `"كانت التجربة جيدة بشكل عام. أسعار التأمينات كانت مناسبة لميزانيتى وكانت التغطية جيدة. ومع ذلك، لاحظت إنهم متاحين أغلب الوقت للرد على أي استفسارات و يقدمون مطالبات لأي جهة و بالهم ما يهدأ حتى يشوفون نتيجة هالمطالبة"`,
    path: "../../../saudimale.svg",
    name: "خالد العنزي",
  },
  {
    id: 3,
    comment: `"أسعارهم جيدة ومستشارين التأمين عندهم استطعوا يفهموا احتياجاتي و اكتشاف بعض المشاكل في الشركة عندي و على هذا الأساس قدموا لي أنواع التأمين .
    "`,
    path: "../../../saudifemale.svg",
    name: "نورة الصالح",
  },
  {
    id: 4,
    comment: `"متعاقد مع جبكو لعدة سنوات كثير متعاونين معي و شغلهم احترافي و يقدمون تغطية شاملة لشركتي ويوفرون حلول مرنة تناسب طبيعة شغلي"`,
    path: "../../../saudifemale.svg",
    name: "عهود الغامدي",
  },
  {
    id: 5,
    comment: `"مستشارين التامين لديهم ساعدوني جداً في شرح حلول التأمين التى تناسب شركتى و على هذا الأساس اخترت بنود وثيقة التأمين بحيث يغطي التأمين احتياجات عملي فقط و ليس أي شي اضافي يكلفنى أعباء مالية و ما اعرف استفيد به"`,
    path: "../../../forign.svg",
    name: "ديفيد سميث",
  },
];
