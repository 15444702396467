import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import CHGrid from "./../Common/CHGrid";
import BlogCard from "../BlogCard/BlogCard";
import blogsData from "./../Data/BlogsData";
import BlogsCatBtn from "./BlogsCatBtn/BlogsCatBtn";

export default function Blogs(x) {
  const [items, setItems] = useState(blogsData);
  const categoryItems = [...new Set(blogsData.map((cat) => cat.category))];

  const filterBlogs = (cat) => {
    const newItems = blogsData.filter((val) => val.category === cat);
    setItems(newItems);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="custom-container">
        <div className="blogsPage">
          <section className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h1 className="text-uppercase">MANTENTE INFORMADO</h1>
            </div>
          </section>
          <section className="blogs-discription text-center w-100 d-flex align-items mx-auto flex-column">
            <div className="blogs-discription-content mx-auto">
              <p className="fw-medium mb-2">
                Stay informed and make good decisions in your organization.
                Enter our blog and learn more about risk management, insurance
                and current events in the sector.
              </p>
            </div>
          </section>
          <section className="blogs-Body">
            <BlogsCatBtn
              cats={categoryItems}
              filterBlogs={filterBlogs}
              setItems={setItems}
            />
            <div className="blogs-Content my-5">
              <BlogCard blogs={items} />
            </div>
          </section>
          <CHGrid />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-4">
              we do things well
            </h2>
            <div className="doingList d-flex ">
              <p>We think about you</p>
              <p>We generate value for you</p>
              <p>We protect you</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contact"
            >
              Contact Us
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}

// function filterBlogs(ele) {
//   let blogsPush = [];
//   if (ele === "All") {
//     blogsData.map((item) => console.log(item));
//     setBlogsData(blogsData);
//     blogsPush = blogsData;
//   } else {
//     blogsData
//       .filter((item) => item.category === ele)
//       .map((item) => blogsPush.push(item));
//     setBlogsData(blogsPush);
//     blogsPush = blogsData;
//     console.log(blogsPush);
//   }
// }

// function addActive(e) {
//   let allItems = document.querySelectorAll(".blogs-category li button");
//   let newAllItems = [...allItems];
//   newAllItems.map((item) => item.classList.remove("active"));
//   !e.target.classList.contains("active") && e.target.classList.add("active");
//   filterBlogs(e.target.id);
// }
