import React from "react";
import "./blogCard.css";
import { NavLink } from "react-router-dom";

const BlogCard = ({ blogs }) => {
  const cards = (
    <div className="blog-pattern d-flex w-100 flex-wrap">
      {blogs.map(
        (
          { id, mainTitle, secondTitle, description, imageURL, date },
          index
        ) => (
          <div className="blog-column" key={index}>
            <div className="blogImage rounded rounded-4">
              <img src={imageURL} alt="ss" className="img-fluid" />
            </div>
            <div className="blogContent p-2">
              <div className="blogDate my-2">{date}</div>
              <div className="blogTitles">
                <h3 className="mainTTitle">{mainTitle}</h3>
                <h5 className="secondTTitle">{secondTitle}</h5>
              </div>
              <p className="blogDescription">{description}</p>
              <NavLink to={`/blogDetailsAr/${id}`} className="btn blogBtn">
                اقرأ المزيد
              </NavLink>
            </div>
          </div>
        )
      )}
    </div>
  );

  return <div className="blog-pattern">{cards}</div>;
};

export default BlogCard;
