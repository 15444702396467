import React from "react";

export default function CHGrid() {
  return (
    <div>
      <section className="choose-grid">
        <div className="choose-grid-title ">
          <h2 className="fw-bold text-center text-uppercase pink-color mb-4">
            Why choose GPCO?
          </h2>
          <div className="container choose-grid-columns d-flex text-white">
            <div className="row flex-wrap g-1">
              <div className="choose-grid-column">
                <div className="choose-grid-item p-3 rounded rounded-4">
                  <p>
                    For over a decade, we've been delivering high-quality
                    services, earning recognition in the insurance market for
                    our expertise, attention and dedicated client support.
                  </p>
                </div>
              </div>
              <div className="col-md-6 p-0 choose-grid-column">
                <div className="p-3 choose-grid-item border rounded rounded-4">
                  <p>
                    We have a team with the knowledge, experience and commitment
                    to make incredible things happen in your life.
                  </p>
                </div>
              </div>
              <div className="col-md-6 p-0 choose-grid-column">
                <div className="p-3 choose-grid-item rounded rounded-4">
                  <p>
                    We design solutions through innovative and complete products
                    especially for your needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
