export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

export const personsFeedbackData = [
  {
    id: 1,
    comment: `"My contract with them was positive once We are characterized by speed and provide you with insurance for exactly what your company needs Many are helpful in explaining the types of insurance to those who benefit your business They are very skilled in processing claims and submitting them to the competent authorities, and they do not abandon the claim until you get your rights I am satisfied with my experience with them in general"`,
    path: "../../../saudimale.svg",
    name: "Jasser Al-Shahrani",
  },

  {
    id: 2,
    comment: `"The experience was good overall. Insurance prices were reasonable for my budget and coverage was good. However, I noticed that they are available most of the time to answer any inquiries, and they submit claims to any party, and their minds do not rest until they see the result of this claim."`,
    path: "../../../saudimale.svg",
    name: "Khaled El Anzi",
  },

  {
    id: 3,
    comment: `"Their prices are good and their insurance consultants were able to understand my needs and discover some problems in my company, and on this basis they provided me with types of insurance."`,
    path: "../../../saudifemale.svg",
    name: "Noura Al-Saleh",
  },
  {
    id: 4,
    comment: `"Contracted with GPCO for several years Many of them cooperate with me and their work is professional. They provide comprehensive coverage for my company and provide flexible solutions that suit the nature of my work."`,
    path: "../../../saudifemale.svg",
    name: "Ohood Al-Ghamdi",
  },
  {
    id: 5,
    comment: `"Insurance advisors were extremely helpful in explaining insurance solutions tailored to my business. Based on their guidance, I selected insurance coverage that meets only my business needs, avoiding any additional expenses that might burden me financially."`,
    path: "../../../forign.svg",
    name: "David Smith",
  },
];
