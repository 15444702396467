import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import patternoccupational1 from "../../../assets/images/Pages/Services/occupational/images/patternoccupational1.jpg";
import patternoccupational2 from "../../../assets/images/Pages/Services/occupational/images/patternoccupational2.jpg";
import patternoccupational3 from "../../../assets/images/Pages/Services/occupational/images/patternoccupational3.jpg";

export default function Occupational() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="occupationalServicesPage">
          <section
            className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden"
            dir="rtl"
          >
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <p className="mb-2">نحب أن نفعل الأشياء بشكل جيد،</p>
              <h1 className="text-uppercase">كن جزءًا من الحل</h1>
            </div>
          </section>
          <section
            className="service-discription w-100 d-flex align-items mx-auto-center flex-column"
            dir="rtl"
          >
            <div className="service-discription-content mx-auto">
              <p className="fw-medium mb-2">
                كوسطاء،
                <span className="fw-bold mx-2">
                  نحن نركز على الدعم الشامل والمشورة ل الشركات بشأن القضايا ذات
                  الصلة
                </span>
                للسلامة والصحة في العمل.
              </p>
            </div>
          </section>
          <section
            className="pattern3 d-flex justify-content-between gap-5"
            dir="rtl"
          >
            <div className="pattern-text">
              <h2 className="mb-3 text-uppercase pink-color fs-1 fw-bolder">
                نحن أفضل حليف لك
              </h2>
              <p className="fw-medium text-capitalize">
                نقدم مجموعة واسعة من حلول التأمين المصممة خصيصًا لتناسب
                احتياجاتك احتياجات العمل اكتشف المنتجات التي تحتاجها.
              </p>
            </div>
            <div className="pattern-images">
              <div className="pattern-images-group">
                <img
                  src={patternoccupational1}
                  alt=""
                  className="pattern-image1"
                />
                <img
                  src={patternoccupational2}
                  alt=""
                  className="pattern-image2"
                />
                <img
                  src={patternoccupational3}
                  alt=""
                  className="pattern-image3"
                />
              </div>
            </div>
          </section>
          <section className="benefits-grid " dir="rtl">
            <div className="benefits-grid-title text-center">
              <h2 className="fw-bolder text-center text-uppercase pink-color mb-4">
                فوائد
              </h2>
              <div className="benefits-grid-columns text-white">
                <div className="row flex-wrap justify-content-center">
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>
                        نحن نضمن إعادة الاستثمار التي يجب على ARL القيام بها يتم
                        بشكل مناسب.
                      </p>
                    </div>
                  </div>
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>
                        نحن نرافق العميل أثناء المطالبة التعويض في حالة وقوع
                        حادث.
                      </p>
                    </div>
                  </div>
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>نحن نساعد في تصميم وتنفيذ برامج إدارة المخاطر.</p>
                    </div>
                  </div>
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>
                        نحن ندعم الشركات في التدخل في المخاطر النفسية
                        والاجتماعية، بموجب القرار 2404 لسنة 2019.
                      </p>
                    </div>
                  </div>
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>
                        نحن نعتني بمصالح العميل وجهاً لوجه المؤمن خلال مدة
                        العقد.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              نحن نفعل الأشياء بشكل جيد
            </h2>
            <div className="doingList d-flex ">
              <p>نحن نفكر فيك</p>
              <p>نحن نولد قيمة بالنسبة لك</p>
              <p>نحن نحميك</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contactAr"
            >
              اتصل بنا
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
