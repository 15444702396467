import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import star from "../../assets/images/Pages/Home/star.svg";
import light from "../../assets/images/Pages/Home/light.svg";
import award from "../../assets/images/Pages/Home/award.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FeedbackAr from "./FeedbackAr";
import { personsFeedbackDataAr, responsive } from "./feedbackDataAr.js";
import patternHome1 from "../../assets/images/Pages/Home/patternHome1.webp";
import patternHome2 from "../../assets/images/Pages/Home/patternHome2.webp";
import patternHome3 from "../../assets/images/Pages/Home/patternHome3.png";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const personFeedback = personsFeedbackDataAr.map((item) => (
    <FeedbackAr
      comment={item.comment}
      pathToImage={item.path}
      name={item.name}
      key={item.id}
    />
  ));
  return (
    <>
      <div className="custom-container">
        <div className="homePage">
          <section
            className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden"
            dir="rtl"
          >
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <p className="mb-2">نحن نؤمن مستقبل أعمال الشركات</p>
              <h1 className="mb-2">ضمان رفاهيتك وحمايتها للمستقبل</h1>
              {/* <NavLink
                className="nav-link d-inline-block mt-4 px-3 py-2 rounded rounded-3 pink-Nav-link"
                to="services"
              >
                How do we do it?
              </NavLink> */}
            </div>
          </section>
          <section
            className="pattern3 d-flex justify-content-between gap-5"
            dir="rtl"
          >
            <div className="pattern-text">
              <h3 className="mb-3 fw-bold fs-5">
                نحن شركة تأمين متكاملة الخدمات.
              </h3>
              <h2 className="mb-3 text-uppercase pink-color fs-1 fw-bolder">
                نحن أفضل حليف لك
              </h2>
              <p className="fw-medium text-capitalize">
                نقدم مجموعة واسعة من حلول التأمين المصممة خصيصًا لتناسب
                احتياجاتك احتياجات العمل اكتشف المنتجات التي تحتاجها.
              </p>
              <NavLink
                className="nav-link d-inline-block mt-4 px-3 py-2 rounded rounded-3 pink-Nav-link text-white"
                to="/servicesAr"
              >
                اكتشف المنتج المناسب لك
              </NavLink>
            </div>
            <div className="pattern-images">
              <div className="pattern-images-group">
                <img src={patternHome1} alt="" className="pattern-image1" />
                <img src={patternHome2} alt="" className="pattern-image2" />
                <img src={patternHome3} alt="" className="pattern-image3" />
              </div>
            </div>
          </section>
          <section className="experience text-center mx-auto w-75" dir="rtl">
            <p className="fw-bold mb-1">نحن وكالة تأمين مع أكثر من</p>
            <h2 className="pink-color fw-bolder text-uppercase mb-1">
              10 سنوات من الخبرة
            </h2>
            <p className="fw-medium">
              التي تقدم المشورة والحلول لإدارة المخاطر و التأمين من خلال منتجات
              مصممة لتناسب احتياجاتك.
            </p>
          </section>
          <section
            className="choose px-5 py-4 rounded rounded-4 text-white d-flex align-items-center"
            dir="rtl"
          >
            <div className="choose-question">
              <h2 className="mb-0 fw-bold text-uppercase fs-3">لماذا</h2>
              <h2 className="mb-0 fw-bold text-uppercase fs-3">تختارنا ؟</h2>
            </div>
            <div className="choose-columns d-flex align-items-center me-5">
              <div className="choose-column">
                <div className="icon ms-3">
                  <img src={star} alt="" />
                </div>
                <div className="discription">
                  <p className="mb-0">
                    فريقنا يصنع <br /> الاختلاف
                  </p>
                </div>
              </div>
              <div className="choose-column">
                <div className="icon ms-3">
                  <img src={award} alt="" />
                </div>
                <div className="discription">
                  <p className="mb-0">
                    10 سنوات <br /> من الخبرة
                  </p>
                </div>
              </div>
              <div className="choose-column">
                <div className="icon ms-3">
                  <img src={light} alt="" />
                </div>
                <div className="discription">
                  <p className="mb-0">
                    نحن نصمم الحلول التي تتكيف مع احتياجاتك
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="choose-grid">
            <div className="choose-grid-title ">
              <h2 className="fw-bold text-center text-uppercase pink-color mb-4">
                المنتجات وفقا لاحتياجاتك
              </h2>
              <div className="container choose-grid-columns d-flex text-white">
                <div className="row flex-wrap g-1">
                  <div className="choose-grid-column fs-5 text-end" dir="rtl">
                    <div className="choose-grid-item p-3 rounded rounded-4 d-flex flex-column ">
                      <h4 className="fw-bold mb-3 ms-auto">المخاطر المهنية</h4>
                      <p className="p-0 w-100">
                        لأكثر من عقد من الزمان، كنا نقدم جودة عالية الخدمات،
                        وكسب الاعتراف في سوق التأمين لخبرتنا واهتمامنا وعميلنا
                        المتفاني يدعم.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 p-0 choose-grid-column fs-5 text-end"
                    dir="rtl"
                  >
                    <div className="p-3 choose-grid-item border rounded rounded-4 d-flex flex-column">
                      <h4 className="fw-bold mb-3 ms-auto">تأمين</h4>
                      <p>
                        لدينا فريق لديه المعرفة والخبرة و الالتزام بتحقيق أشياء
                        مذهلة في حياتك حياة.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 p-0 choose-grid-column fs-5 text-end"
                    dir="rtl"
                  >
                    <div className="p-3 choose-grid-item rounded rounded-4 flex flex-column">
                      <h4 className="fw-bold mb-3 ms-auto">الاستشارة</h4>
                      <p>
                        نقوم بتصميم الحلول من خلال مبتكرة وكاملة المنتجات خصيصا
                        لاحتياجاتك.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="testmonials" dir="rtl">
            <div className="testmonails-text mb-5">
              <h2 className="pink-color fw-bold text-uppercase mb-1">
                الشهادات - التوصيات
              </h2>
              <p className="fw-medium" dir="rtl">
                نحن متحمسون لمشاركة مدى رضا عملائنا.
              </p>
            </div>
            <Carousel
              swipeable={false}
              draggable={false}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1750}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              responsive={responsive}
            >
              {personFeedback}
            </Carousel>
          </section>
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              نحن نفعل الأشياء بشكل جيد
            </h2>
            <div className="doingList d-flex ">
              <p>نحن نفكر فيك</p>
              <p>نحن نولد قيمة بالنسبة لك</p>
              <p>نحن نحميك</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contactAr"
            >
              اتصل بنا
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
