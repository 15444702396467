import React, { useEffect, useRef, useState } from "react";
import Iframe from "react-iframe";
import star from "../../assets/images/Pages/Home/star.svg";
import light from "../../assets/images/Pages/Home/light.svg";
import award from "../../assets/images/Pages/Home/award.svg";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "service_21vbfup",
        "template_jugnvho",
        form.current,
        "8P3R4bveK9xEUzEw8"
      )
      .then(
        (result) => {
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );

    // console.log(e);
  };

  // const refresh = () => {
  //   window.location.reload(true);
  // };

  return (
    <>
      <div className="custom-container">
        <div className="contactPage">
          <section className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h1 className="mb-2">We'd like to hear from you</h1>
              {/* <NavLink
                className="nav-link d-inline-block mt-4 px-3 py-2 rounded rounded-3 pink-Nav-link"
                to="services"
              >
                How do we do it?
              </NavLink> */}
            </div>
          </section>
          <section className="choose px-5 py-4 rounded rounded-4 text-white d-flex align-items-center">
            <div className="choose-question">
              <h2 className="mb-0 fw-bold text-uppercase fs-3">Why</h2>
              <h2 className="mb-0 fw-bold text-uppercase fs-3">
                you coose us ?
              </h2>
            </div>
            <div className="choose-columns d-flex align-items-center ms-5">
              <div className="choose-column">
                <div className="icon me-3">
                  <img src={star} alt="" />
                </div>
                <div className="discription">
                  <p className="mb-0">
                    Our team makes the <br /> difference
                  </p>
                </div>
              </div>
              <div className="choose-column">
                <div className="icon me-3">
                  <img src={award} alt="" />
                </div>
                <div className="discription">
                  <p className="mb-0">
                    10 years <br /> expertise
                  </p>
                </div>
              </div>
              <div className="choose-column">
                <div className="icon me-3">
                  <img src={light} alt="" />
                </div>
                <div className="discription">
                  <p className="mb-0">
                    We design solutions that Adapts to your needs
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="info d-flex mx-auto w-75 flex-wrap">
            <div className="row">
              <div className="info-column col-md-12 p-2">
                <div className="title fs-5 mb-2">
                  <i className="fa-solid fa-location-dot me-2 pink-color"></i>
                  <span className="text-uppercase fw-bold">location</span>
                </div>
                <div className="details">
                  <p className="fw-medium">
                    Al Umam Commercial Center 3rd Floor Unit : 331 Salah Ad Din
                    Al Ayyubi Road P.O. Box 27663 Riyadh 11427 Kingdom of Saudi
                    Arabia
                  </p>
                </div>
              </div>
              <div className="info-column col-lg-6 col-md-6 p-2">
                <div className="title fs-5 mb-2">
                  <i className="fa-solid fa-envelope me-2 pink-color"></i>
                  <span className="text-uppercase fw-bold">mail</span>
                </div>
                <div className="details">
                  <p className="fw-medium">info@gpco.com.sa</p>
                </div>
              </div>
              <div className="info-column col-lg-6 col-md-6 p-2">
                <div className="title fs-5 mb-2">
                  <i className="fa-solid fa-phone me-2 pink-color"></i>
                  <span className="text-uppercase fw-bold">phone</span>
                </div>
                <div className="details">
                  <p className="fw-medium">+966 112925599</p>
                </div>
              </div>
            </div>
          </section>
          <section className="contactForm text-white p-5">
            <div className="from-title my-5 text-center position-relative">
              <h2 className="fw-bold position-relative">
                Tell us how we can serve you
              </h2>
            </div>
            <form
              className="form-body my-5 d-flex flex-wrap mx-auto"
              onSubmit={(e) => {
                sendEmail(e);
              }}
              ref={form}
            >
              <input
                type="text"
                placeholder="Your Name"
                name="from_name"
                minLength={3}
                maxLength={20}
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                placeholder="Your Email"
                name="from_email"
                minLength={12}
                maxLength={30}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <input
                type="tel"
                placeholder="Phone Number"
                name="from_phone"
                minLength={11}
                maxLength={14}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                required
              />
              <input
                type="text"
                placeholder="Country"
                name="from_country"
                minLength={3}
                maxLength={20}
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
                required
              />
              <input
                type="text"
                placeholder="Company Name"
                name="from_company"
                minLength={3}
                maxLength={20}
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                required
              />
              <input
                type="text"
                placeholder="Subject"
                name="subject"
                minLength={3}
                maxLength={20}
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                required
              />
              <textarea
                name="message"
                placeholder="Message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                required
              ></textarea>
              <input
                type="submit"
                className="ms-auto fw-medium px-5 py-1 outline outline-0 border border-0 text-white rounded rounded-4"
                value="Send"
                // onClick={refresh}
              />
            </form>
          </section>
          <section>
            <Iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.1117220548426!2d46.723652200000004!3d24.688685900000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f05eddea143f7%3A0xb50c1667c11272d4!2z2KfZhNmI2KvZitmC2Kkg2KfZhNi02KfZhdmE2Kkg2YTZiNiz2KfYt9ipINin2YTYqtij2YXZitmGIC0g2KzYqNmD2YggLSBHUENP!5e0!3m2!1sen!2ssa!4v1698057227114!5m2!1sen!2ssa"
              width="100%"
              height="600"
              allowfullscreen=""
              loading="lazy"
            ></Iframe>
          </section>
        </div>
      </div>
    </>
  );
}
