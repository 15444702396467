import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import BlogCardAr from "../BlogCard/BlogCardAr";
import blogsDataAr from "./../Data/BlogsDataAr";
import BlogsCatBtnAr from "./BlogsCatBtn/BlogsCatBtnAr";
import CHGridAr from "../Common/CHGridAr";

export default function BlogsAr() {
  const [items, setItems] = useState(blogsDataAr);
  const categoryItems = [...new Set(blogsDataAr.map((cat) => cat.category))];

  const filterBlogs = (cat) => {
    const newItems = blogsDataAr.filter((val) => val.category === cat);
    setItems(newItems);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="custom-container">
        <div className="blogsPage">
          <section
            className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden"
            dir="rtl"
          >
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h1 className="text-uppercase">معلومات أساسية</h1>
            </div>
          </section>
          <section
            className="blogs-discription text-center w-100 d-flex align-items mx-auto flex-column"
            dir="rtl"
          >
            <div className="blogs-discription-content mx-auto">
              <p className="fw-medium mb-2">
                ابق على اطلاع واتخذ قرارات جيدة في مؤسستك. أدخل مدونتنا وتعرف
                على المزيد حول إدارة المخاطر والتأمين والأحداث الجارية في
                القطاع.
              </p>
            </div>
          </section>
          <section className="blogs-Body" dir="rtl">
            <BlogsCatBtnAr
              cats={categoryItems}
              filterBlogs={filterBlogs}
              setItems={setItems}
            />
            <div className="blogs-Content my-5">
              <BlogCardAr blogs={items} />
            </div>
          </section>
          <CHGridAr />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              نحن نفعل الأشياء بشكل جيد
            </h2>
            <div className="doingList d-flex ">
              <p>نحن نفكر فيك</p>
              <p>نحن نولد قيمة بالنسبة لك</p>
              <p>نحن نحميك</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contactAr"
            >
              اتصل بنا
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}

// function filterBlogs(ele) {
//   let blogsPush = [];
//   if (ele === "All") {
//     blogsData.map((item) => console.log(item));
//     setBlogsData(blogsData);
//     blogsPush = blogsData;
//   } else {
//     blogsData
//       .filter((item) => item.category === ele)
//       .map((item) => blogsPush.push(item));
//     setBlogsData(blogsPush);
//     blogsPush = blogsData;
//     console.log(blogsPush);
//   }
// }

// function addActive(e) {
//   let allItems = document.querySelectorAll(".blogs-category li button");
//   let newAllItems = [...allItems];
//   newAllItems.map((item) => item.classList.remove("active"));
//   !e.target.classList.contains("active") && e.target.classList.add("active");
//   filterBlogs(e.target.id);
// }
