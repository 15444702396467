import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Home from "./Components/Home/Home";
import HomeAr from "./Components/Home/HomeAr";
import About from "./Components/About/About";
import AboutAr from "./Components/About/AboutAr";
import Services from "./Components/Services/Services";
import ServicesAr from "./Components/Services/ServicesAr";
import Blogs from "./Components/Blogs/Blogs";
import BlogsAr from "./Components/Blogs/BlogsAr";
import Contact from "./Components/Contact/Contact";
import ContactAr from "./Components/Contact/ContactAr";
import Notfound from "./Components/Notfound/Notfound";
import Corporate from "./Components/Services/Corporate/Corporate";
import CorporateAr from "./Components/Services/Corporate/CorporateAr";
import Occupational from "./Components/Services/Occupational/Occupational";
import OccupationalAr from "./Components/Services/Occupational/OccupationalAr";
import Management from "./Components/Services/Management/Management";
import ManagementAr from "./Components/Services/Management/ManagementAr";
import Claims from "./Components/Services/Claims/Claims";
import ClaimsAr from "./Components/Services/Claims/ClaimsAr";
import BlogDetails from "./Components/BlogDetails/BlogDetails";
import BlogDetailsAr from "./Components/BlogDetails/BlogDetailsAr";
import SquareLoader from "react-spinners/SquareLoader";
import { useState, useEffect } from "react";

export default function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const routes = createBrowserRouter([
    {
      path: "/",
      element: <Layout></Layout>,
      children: [
        { index: true, element: <Home></Home> },
        { path: "homeAr", element: <HomeAr></HomeAr> },
        { path: "about", element: <About></About> },
        { path: "aboutAr", element: <AboutAr></AboutAr> },
        { path: "services", element: <Services></Services> },
        { path: "servicesAr", element: <ServicesAr></ServicesAr> },
        { path: "blogs", element: <Blogs></Blogs> },
        { path: "blogsAr", element: <BlogsAr></BlogsAr> },
        { path: "contact", element: <Contact></Contact> },
        { path: "contactAr", element: <ContactAr></ContactAr> },
        { path: "*", element: <Notfound></Notfound> },
        { path: "blogDetails/:id", element: <BlogDetails></BlogDetails> },
        { path: "blogDetailsAr/:id", element: <BlogDetailsAr></BlogDetailsAr> },

        { path: "services/corporate", element: <Corporate></Corporate> },
        {
          path: "servicesAr/corporateAr",
          element: <CorporateAr></CorporateAr>,
        },
        {
          path: "services/occupational",
          element: <Occupational></Occupational>,
        },
        {
          path: "servicesAr/occupationalAr",
          element: <OccupationalAr></OccupationalAr>,
        },
        { path: "services/management", element: <Management></Management> },
        {
          path: "servicesAr/managementAr",
          element: <ManagementAr></ManagementAr>,
        },
        { path: "services/claims", element: <Claims></Claims> },
        { path: "servicesAr/claimsAr", element: <ClaimsAr></ClaimsAr> },
      ],
    },
  ]);
  return (
    <>
      {loading ? (
        <div className="w-100 bg-white vh-100 d-flex justify-content-center align-items-center">
          <SquareLoader color="#3c489e" size={60} speedMultiplier={0.85} />
        </div>
      ) : (
        <RouterProvider router={routes}></RouterProvider>
      )}
    </>
  );
}
