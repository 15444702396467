import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import CHGrid from "../Common/CHGrid";

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="servicesPage">
          <section className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <p className="mb-2">We are to search</p>
              <h1 className="text-uppercase">WHAT YOU NEED</h1>
            </div>
          </section>
          <section className="services-discription text-center w-100 d-flex align-items mx-auto-center flex-column">
            <div className="services-discription-content mx-auto">
              <p className="fw-medium mb-2">
                We know the effort it takes to materialize a dream and the
                importance of taking care of your heritage, that's why
              </p>
              <p className="fw-bold">
                We design insurance focused on your needs.
              </p>
            </div>
          </section>
          <section className="services-blocks d-flex flex-wrap">
            <div className="services-block services-block1 rounded rounded-4 p-4">
              <div className="service-block-content d-flex flex-column justify-content-end align-items-center h-100 text-white position-relative text-center p-3">
                <h3 className="fw-bold mb-3">CORPORATE INSURANCE</h3>
                <p>
                  Business insurance protects your interests and ensure that
                  everything will continue to work, even if there is a
                  unexpected.
                </p>
                <NavLink
                  className="nav-link text-capitalize text-white pink-Nav-link d-inline-block px-4 py-2 rounded rounded-3"
                  to="corporate"
                >
                  learn more
                </NavLink>
              </div>
            </div>
            <div className="services-block services-block2 rounded rounded-4 p-4">
              <div className="service-block-content d-flex flex-column justify-content-end align-items-center h-100 text-white position-relative text-center p-3">
                <h3 className="fw-bold mb-3">OCCUPATIONAL HAZARDS</h3>
                <p>
                  We focus on comprehensively supporting and advising companies
                  on issues related to Security and Health at Work.
                </p>
                <NavLink
                  className="nav-link text-capitalize text-white pink-Nav-link d-inline-block px-4 py-2 rounded rounded-3"
                  to="occupational"
                >
                  learn more
                </NavLink>
              </div>
            </div>
            <div className="services-block services-block3 rounded rounded-4 p-4">
              <div className="service-block-content d-flex flex-column justify-content-end align-items-center h-100 text-white position-relative text-center p-3">
                <h3 className="fw-bold mb-3">ADVICE ON RISK MANAGEMENT</h3>
                <p>
                  Our approach is to identify, evaluate and manage each risk in
                  a way global.
                </p>
                <NavLink
                  className="nav-link text-capitalize text-white pink-Nav-link d-inline-block px-4 py-2 rounded rounded-3"
                  to="management"
                >
                  learn more
                </NavLink>
              </div>
            </div>
            <div className="services-block services-block4 rounded rounded-4 p-4">
              <div className="service-block-content d-flex flex-column justify-content-end align-items-center h-100 text-white position-relative text-center p-3">
                <h3 className="fw-bold mb-3">ADVICE ON CLAIMS</h3>
                <p>
                  We offer you an accompaniment permanent in the claim process.
                </p>
                <NavLink
                  className="nav-link text-capitalize text-white pink-Nav-link d-inline-block px-4 py-2 rounded rounded-3"
                  to="claims"
                >
                  learn more
                </NavLink>
              </div>
            </div>
          </section>
          <section className="purpose d-flex justify-content-center align-items-center flex-column rounded rounded-3 overflow-hidden text-white">
            <div className="purposeContent text-center">
              <h2 className="fw-bolder text-uppercase">Our purpose</h2>
              <p className="w-75 fs-5 text-center m-auto fw-medium">
                Enhance companies' well-being and ensure their future
                protection.
              </p>
            </div>
          </section>
          <CHGrid />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-4">
              we do things well
            </h2>
            <div className="doingList d-flex ">
              <p>We think about you</p>
              <p>We generate value for you</p>
              <p>We protect you</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contact"
            >
              Contact Us
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
