import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import patternoccupational1 from "../../../assets/images/Pages/Services/occupational/images/patternoccupational1.jpg";
import patternoccupational2 from "../../../assets/images/Pages/Services/occupational/images/patternoccupational2.jpg";
import patternoccupational3 from "../../../assets/images/Pages/Services/occupational/images/patternoccupational3.jpg";

export default function Occupational() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="occupationalServicesPage">
          <section className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <p className="mb-2">We like to do things well,</p>
              <h1 className="text-uppercase">BE PART OF THE SOLUTION</h1>
            </div>
          </section>
          <section className="service-discription w-100 d-flex align-items mx-auto-center flex-column">
            <div className="service-discription-content mx-auto">
              <p className="fw-medium mb-2">
                As intermediaries,
                <span className="fw-bold mx-2">
                  We focus on comprehensively supporting and advising to
                  companies on issues related
                </span>
                to Safety and Health at Work.
              </p>
            </div>
          </section>
          <section className="pattern3 d-flex justify-content-between gap-5">
            <div className="pattern-text">
              <h2 className="mb-3 text-uppercase pink-color fs-1 fw-bolder">
                we are you best ally
              </h2>
              <p className="fw-medium text-capitalize">
                offering a wide range of insurance solutions tailored to your
                business needs Discover the Products you need.
              </p>
            </div>
            <div className="pattern-images">
              <div className="pattern-images-group">
                <img
                  src={patternoccupational1}
                  alt=""
                  className="pattern-image1"
                />
                <img
                  src={patternoccupational2}
                  alt=""
                  className="pattern-image2"
                />
                <img
                  src={patternoccupational3}
                  alt=""
                  className="pattern-image3"
                />
              </div>
            </div>
          </section>
          <section className="benefits-grid ">
            <div className="benefits-grid-title text-center">
              <h2 className="fw-bolder text-center text-uppercase pink-color mb-4">
                benefits
              </h2>
              <div className="benefits-grid-columns text-white">
                <div className="row flex-wrap justify-content-center">
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>
                        We ensure that the reinvestment that the ARL must make
                        is done appropriately.
                      </p>
                    </div>
                  </div>
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>
                        We accompany the client during the claim for
                        compensation in the event of an accident.
                      </p>
                    </div>
                  </div>
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>
                        We help design and implement risk management programs.
                      </p>
                    </div>
                  </div>
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>
                        We support companies in psychosocial risk intervention,
                        according to resolution 2404 of 2019.
                      </p>
                    </div>
                  </div>
                  <div className="benefits-grid-column p-2 col-lg-4 col-md-6">
                    <div className="benefits-grid-column-item rounded rounded-4 p-4">
                      <p>
                        We look after the client's interests vis-à-vis the
                        insurer during the term of the contract.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              we do things well
            </h2>
            <div className="doingList d-flex ">
              <p>We think about you</p>
              <p>We generate value for you</p>
              <p>We protect you</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contact"
            >
              Contact Us
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
