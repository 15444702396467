import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import patternClaims1 from "../../../assets/images/Pages/Services/claims/images/claims-image1.jpg";
import patternClaims2 from "../../../assets/images/Pages/Services/claims/images/claims-image2.jpg";
import patternClaims3 from "../../../assets/images/Pages/Services/claims/images/claims-image3.jpg";
import CHGrid from "../../Common/CHGrid";

export default function Claims() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="claimsServicesPage">
          <section className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h1 className="fw-bolder mb-0">ADVICE ON CLAIMS</h1>
            </div>
          </section>
          <section className="service-discription w-100.. d-flex align-items mx-auto-center flex-column">
            <div className="service-discription-content mx-auto">
              <p className="fw-medium mb-2">
                The claims service helps your company submit claims and obtain
                compensation in the event of financial losses or damage to your
                business. This service includes receiving claims, estimating
                damages, and processing them until you obtain appropriate
                compensation.
              </p>
            </div>
          </section>
          <section className="pattern3 d-flex justify-content-between gap-5">
            <div className="pattern-text">
              <ol className="claims-list nav-list fw-medium">
                <li className="nav-item">
                  <p>
                    Insurance claims: to obtain financial compensation in the
                    event of accidents or losses covered by insurance policies.
                  </p>
                </li>
                <li className="nav-item">
                  <p>
                    Compensation claims: to obtain compensation from other
                    parties in the event that these parties cause losses or
                    damage to the company.
                  </p>
                </li>
                <li className="nav-item">
                  <p>
                    Claims for commercial damages in the event of commercial
                    damages resulting from a delay in the delivery of goods or
                    services.
                  </p>
                </li>
                <li className="nav-item">
                  <p>
                    Warranty claims: If the products or services provided by the
                    company do not meet the agreed specifications, the company
                    can submit claims to demand that the products be repaired or
                    replaced or that additional services be provided.
                  </p>
                </li>
                <li className="nav-item">
                  <p>
                    Claims for compensation for legal damages: In some cases,
                    companies can file legal claims to obtain compensation for
                    damages that may be the result of the illegal actions of
                    other parties.
                  </p>
                </li>
              </ol>
            </div>
            <div className="pattern-images">
              <div className="pattern-images-group">
                <img src={patternClaims1} alt="" className="pattern-image1" />
                <img src={patternClaims2} alt="" className="pattern-image2" />
                <img src={patternClaims3} alt="" className="pattern-image3" />
              </div>
            </div>
          </section>
          <section className="claims-discription d-flex justify-content-center align-items-center text-white text-center rounded rounded-5">
            <p className="fs-5 z-1 fw-bold">
              During our 10 years of experience, we have provided consultations
              on the management of numerous incidents whose amounts exceeded SR
              25,000 per incident.
            </p>
          </section>
          <CHGrid />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              we do things well
            </h2>
            <div className="doingList d-flex ">
              <p>We think about you</p>
              <p>We generate value for you</p>
              <p>We protect you</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contact"
            >
              Contact Us
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
