import React, { useEffect } from "react";
// import papaerFly from "../../assets/images/Papers.png";

export default function Notfound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-gradient-darkBlue mainSectionheight position-relative">
      <div className="notFoundBackImage"></div>
      <div className="notFountHeaderContent text-white position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
        <h1 className="mb-2 text-center">404 Error</h1>
        <p className="m-0 text-center">عفوا, هذه الصفحة غير موجودة</p>
        <p className="m-0 text-center">Sorry, page not found</p>
        <p className="m-0 text-center">
          The page you were looking for doesn’t exist.
        </p>
      </div>
    </div>
  );
}
