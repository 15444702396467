import React, { useState } from "react";
import Navbar from "./../Navbar/Navbar";
import NavbarAr from "./../Navbar/NavbarAr";
import { Outlet } from "react-router-dom";
import Footer from "./../Footer/Footer";
import FooterAr from "./../Footer/FooterAr";
import Rectangle1 from "../../assets/shapes/Rectangle-1.svg";
import Rectangle2 from "../../assets/shapes/Rectangle-2.svg";
import Rectangle3 from "../../assets/shapes/Rectangle-3.svg";
import Rectangle4 from "../../assets/shapes/Rectangle-4.svg";

export default function Layout() {
  const [flag, setFlag] = useState("EN");

  return (
    <div className="overflow-hidden">
      {flag === "EN" ? (
        <Navbar setFlag={setFlag} />
      ) : (
        <NavbarAr setFlag={setFlag} />
      )}

      <div className="main position-relative">
        <img
          className="Rectangle Rectangle1 position-absolute"
          src={Rectangle1}
          alt=""
        />
        <img
          className="Rectangle Rectangle2 position-absolute"
          src={Rectangle2}
          alt=""
        />
        <img
          className="Rectangle Rectangle3 position-absolute"
          src={Rectangle3}
          alt=""
        />
        <img
          className="Rectangle Rectangle4 position-absolute"
          src={Rectangle4}
          alt=""
        />
        <Outlet></Outlet>
      </div>
      {flag === "EN" ? (
        <Footer setFlag={setFlag} />
      ) : (
        <FooterAr setFlag={setFlag} />
      )}
    </div>
  );
}
