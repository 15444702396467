import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import benefitsImage from "../../../assets/images/Pages/Services/managments/images/benefits.jpg";
import CHGridAr from "../../Common/CHGridAr";

export default function ManagementAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="managementServicesPage">
          <section
            className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden"
            dir="rtl"
          >
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h2 className="fw-bolder mb-0">
                الثبات والاستدامة مع مرور الوقت
              </h2>
              <p className="mb-3 fs-4">من أي عمل يعتمد على كافية</p>
              <h1 className="text-uppercase">إدارة المخاطر</h1>
            </div>
          </section>
          <section
            className="service-discription w-100 d-flex align-items mx-auto-center flex-column"
            dir="rtl"
          >
            <div className="service-discription-content mx-auto">
              <p className="fw-medium mb-2">
                تركيزنا هو
                <span className="fw-bold mx-2">تحديد وتقييم وإدارة</span>
                كل خطر بطريقة عالمية، ولهذا السبب، فإننا نأخذ أهداف المنظمة
                كأساس، من أجل هيكلة أ عملية خلق القيمة التي تضمن الامتثال بشكل
                مسؤول مع هؤلاء.
              </p>
            </div>
          </section>
          <section className="management-block" dir="rtl">
            <div className="management-block-title">
              <h2 className="fw-bolder text-uppercase text-center pink-color mb-4">
                فوائد
              </h2>
            </div>
            <div className="management-block-body d-flex justify-content-between align-items-center flex-wrap">
              <div className="management-block-text">
                <ul className="management-list nav-list fw-medium">
                  <li className="nav-item">
                    <p>الحد من المخاطر المالية واستقرار الشركة</p>
                  </li>
                  <li className="nav-item">
                    <p>
                      تهيئة الشركة للتعامل مع الأزمات والكوارث. مما يقلل من
                      تأثيرها السلبي
                    </p>
                  </li>
                  <li className="nav-item">
                    <p>
                      تحسين عملياتها وزيادة عملياتها كفاءة. إذا تم تحديد مناطق
                      الضعف، فيمكنهم ذلك تحسينها لتحقيق أقصى فائدة
                    </p>
                  </li>
                  <li className="nav-item">
                    <p>
                      تحديد المخاطر التي يمكن تغطيتها بالتأمين، و توفير تكاليف
                      التأمين غير الضرورية
                    </p>
                  </li>
                </ul>
              </div>
              <div className="management-block-image">
                <img src={benefitsImage} alt="" className="rounded rounded-4" />
              </div>
            </div>
          </section>
          <section className="note-block" dir="rtl">
            <h2 className="fs-1 text-uppercase fw-bolder pink-color text-center mb-3">
              وتجدر الإشارة إلى ذلك
            </h2>
            <p className="mx-auto text-center fw-medium">
              هذه البدائل ليست بديلاً لسوق المنتجات الأمان والطمأنينة، ولكن هناك
              تكملة لهذه الحالة، وبعضها الآخر ابن هذه المركبات:
            </p>
          </section>
          <section className="management-grid " dir="rtl">
            <div className="management-grid-columns d-flex text-white text-end">
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>سوسيداد كاتيفاس</p>
              </div>
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>
                  مجموعات الاحتفاظ بالريسجوس
                  <span className="fw-normal fs-6 mx-1">"سوسيدادس موتواس"</span>
                </p>
              </div>
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>
                  مشتقات الصكوك
                  <span className="fw-normal fs-6 mx-1">
                    "التقدم للأمام، المستقبل، الخيارات والمقايضات"
                  </span>
                </p>
              </div>
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>عناوين RIESGOS "ILS"</p>
              </div>
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>أدوات رأس المال للطوارئ</p>
              </div>
            </div>
          </section>
          <section className="management-discription" dir="rtl">
            <p className="fw-medium fs-5">
              لقد استخدمنا في شركة GPCO وسيلة نقل المخاطر هذه لأكثر من 10 مرات
              سنوات، والتي تتكون من تشكيل شركة غرضها هو تقديم تغطية التأمين أو
              إعادة التأمين، حصريًا لـ مخاطر الشركة أو الشركات التي ينتمي إليها
              أو بالنسبة لها المجموعة التي هو جزء منها.
            </p>
          </section>
          <CHGridAr />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              نحن نفعل الأشياء بشكل جيد
            </h2>
            <div className="doingList d-flex ">
              <p>نحن نفكر فيك</p>
              <p>نحن نولد قيمة بالنسبة لك</p>
              <p>نحن نحميك</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contactAr"
            >
              اتصل بنا
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
