import React from "react";
import blogsDataAr from "../../Data/BlogsDataAr";

const BlogsCatBtnAr = ({ cats, filterBlogs, setItems }) => {
  return (
    <div className="blogsCatBtns ">
      <ul className="d-flex justify-content-center flex-wrap">
        <li
          className="active mx-3"
          onClick={() => {
            setItems(blogsDataAr);
          }}
        >
          الكل
        </li>
        {cats.map((item, index) => (
          <li
            className="mx-3"
            key={index}
            onClick={() => {
              filterBlogs(item);
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogsCatBtnAr;

/**
 * 
 *   <ul className="blogs-category nav-list fw-bold w-100 d-flex justify-content-center flex-wrap">
        <li className="nav-item mx-2">
          <button
            className="nav-link text-capitalize active"
            onClick={(e) => {}}
            id="All"
          >
            All Categories
          </button>
        </li>
        <li className="nav-item mx-2">
          <button
            className="nav-link text-capitalize"
            onClick={(e) => {}}
            id="Insurance"
          >
            Insurance
          </button>
        </li>
        <li className="nav-item mx-2">
          <button
            className="nav-link text-capitalize"
            onClick={(e) => {}}
            id="Risk"
          >
            Risk management
          </button>
        </li>
        <li className="nav-item mx-2">
          <button
            className="nav-link text-capitalize"
            onClick={(e) => {}}
            id="News"
          >
            Sector News
          </button>
        </li>
        <li className="nav-item mx-3">
          <button
            className="nav-link text-capitalize"
            onClick={(e) => {}}
            id="Other"
          >
            Other topics of interest
          </button>
        </li>
      </ul>
 * 
 * **/
