import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import star from "../../assets/images/Pages/Home/star.svg";
import light from "../../assets/images/Pages/Home/light.svg";
import award from "../../assets/images/Pages/Home/award.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Feedback from "./Feedback";
import { personsFeedbackData, responsive } from "./feedbackData.js";
import patternHome1 from "../../assets/images/Pages/Home/patternHome1.webp";
import patternHome2 from "../../assets/images/Pages/Home/patternHome2.webp";
import patternHome3 from "../../assets/images/Pages/Home/patternHome3.png";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const personFeedback = personsFeedbackData.map((item) => (
    <Feedback
      comment={item.comment}
      pathToImage={item.path}
      name={item.name}
      key={item.id}
    />
  ));
  // personsFeedbackData.map((item) => console.log(item));
  return (
    <>
      <div className="custom-container">
        <div className="homePage">
          <section className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <p className="mb-2">We insure the future of corporate business</p>
              <h1 className="mb-2">
                Ensuring your well-being and protection for the future
              </h1>
              {/* <NavLink
                className="nav-link d-inline-block mt-4 px-3 py-2 rounded rounded-3 pink-Nav-link"
                to="services"
              >
                How do we do it?
              </NavLink> */}
            </div>
          </section>
          <section className="pattern3 d-flex justify-content-between gap-5">
            <div className="pattern-text">
              <h3 className="mb-3 fw-bold fs-5">
                We're a full-service insurance company.
              </h3>
              <h2 className="mb-3 text-uppercase pink-color fs-1 fw-bolder">
                we are you best ally
              </h2>
              <p className="fw-medium text-capitalize">
                offering a wide range of insurance solutions tailored to your
                business needs Discover the Products you need.
              </p>
              <NavLink
                className="nav-link d-inline-block mt-4 px-3 py-2 rounded rounded-3 pink-Nav-link text-white"
                to="services"
              >
                Discover the product for you
              </NavLink>
            </div>
            <div className="pattern-images">
              <div className="pattern-images-group">
                <img src={patternHome1} alt="" className="pattern-image1" />
                <img src={patternHome2} alt="" className="pattern-image2" />
                <img src={patternHome3} alt="" className="pattern-image3" />
              </div>
            </div>
          </section>
          <section className="experience text-center mx-auto w-75">
            <p className="fw-bold mb-1">
              We are an insurance agency with more than
            </p>
            <h2 className="pink-color fw-bolder text-uppercase mb-1">
              10 YEARS OF EXPERIENCE
            </h2>
            <p className="fw-medium">
              that provides advice and solutions for risk management and
              insurance through products designed to suit your needs.
            </p>
          </section>
          <section className="choose px-5 py-4 rounded rounded-4 text-white d-flex align-items-center">
            <div className="choose-question">
              <h2 className="mb-0 fw-bold text-uppercase fs-3">Why</h2>
              <h2 className="mb-0 fw-bold text-uppercase fs-3">
                you coose us ?
              </h2>
            </div>
            <div className="choose-columns d-flex align-items-center ms-5">
              <div className="choose-column">
                <div className="icon me-3">
                  <img src={star} alt="" />
                </div>
                <div className="discription">
                  <p className="mb-0">
                    Our team makes the <br /> difference
                  </p>
                </div>
              </div>
              <div className="choose-column">
                <div className="icon me-3">
                  <img src={award} alt="" />
                </div>
                <div className="discription">
                  <p className="mb-0">
                    10 years <br /> expertise
                  </p>
                </div>
              </div>
              <div className="choose-column">
                <div className="icon me-3">
                  <img src={light} alt="" />
                </div>
                <div className="discription">
                  <p className="mb-0">
                    We design solutions that Adapts to your needs
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="choose-grid">
            <div className="choose-grid-title ">
              <h2 className="fw-bold text-center text-uppercase pink-color mb-4">
                PRODUCTS ACCORDING TO YOUR NEED
              </h2>
              <div className="container choose-grid-columns d-flex text-white">
                <div className="row flex-wrap g-1">
                  <div className="choose-grid-column">
                    <div className="choose-grid-item p-3 rounded rounded-4 d-flex flex-column">
                      <h4 className="fw-bold mb-3 me-auto">
                        OCCUPATIONAL HAZARDS
                      </h4>
                      <p>
                        For over a decade, we've been delivering high-quality
                        services, earning recognition in the insurance market
                        for our expertise, attention and dedicated client
                        support.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 p-0 choose-grid-column">
                    <div className="p-3 choose-grid-item border rounded rounded-4 d-flex flex-column">
                      <h4 className="fw-bold mb-3 me-auto">INSURANCE</h4>
                      <p>
                        We have a team with the knowledge, experience and
                        commitment to make incredible things happen in your
                        life.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 p-0 choose-grid-column">
                    <div className="p-3 choose-grid-item rounded rounded-4 flex flex-column">
                      <h4 className="fw-bold mb-3 me-auto">CONSULTING</h4>
                      <p>
                        We design solutions through innovative and complete
                        products especially for your needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="testmonials">
            <div className="testmonails-text mb-5">
              <h2 className="pink-color fw-bold text-uppercase mb-1">
                testmonials
              </h2>
              <p className="fw-medium">
                We are excited to share how satisfied our customers are.
              </p>
            </div>
            <Carousel
              swipeable={false}
              draggable={false}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1750}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              responsive={responsive}
            >
              {personFeedback}
            </Carousel>
          </section>
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              we do things well
            </h2>
            <div className="doingList d-flex ">
              <p>We think about you</p>
              <p>We generate value for you</p>
              <p>We protect you</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="contact"
            >
              Contact Us
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
