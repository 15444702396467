import React from "react";

export default function CHGridAr() {
  return (
    <div>
      <section className="choose-grid" dir="rtl">
        <div className="choose-grid-title ">
          <h2 className="fw-bold text-center text-uppercase pink-color mb-4">
            لماذا تختار جيبكو؟
          </h2>
          <div className="container choose-grid-columns d-flex text-white fs-5">
            <div className="row flex-wrap g-1">
              <div className="choose-grid-column">
                <div className="choose-grid-item p-3 rounded rounded-4">
                  <p>
                    لأكثر من عقد من الزمان، كنا نقدم جودة عالية الخدمات، وكسب
                    الاعتراف في سوق التأمين ل خبرتنا واهتمامنا ودعمنا المخصص
                    للعملاء.
                  </p>
                </div>
              </div>
              <div className="col-md-6 p-0 choose-grid-column">
                <div className="p-3 choose-grid-item border rounded rounded-4">
                  <p>
                    لدينا فريق يتمتع بالمعرفة والخبرة والالتزام لجعل أشياء لا
                    تصدق تحدث في حياتك.
                  </p>
                </div>
              </div>
              <div className="col-md-6 p-0 choose-grid-column">
                <div className="p-3 choose-grid-item rounded rounded-4">
                  <p>
                    نقوم بتصميم الحلول من خلال منتجات مبتكرة وكاملة خاصة بالنسبة
                    لاحتياجاتك.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
