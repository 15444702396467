import React, { useEffect } from "react";
import presindentImage from "../../assets/images/Pages/About/presindentImage.jpeg";
import workersImage from "../../assets/images/Pages/About/workersImage.jpg";
import { NavLink } from "react-router-dom";
import CHGrid from "./../Common/CHGrid";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="aboutPage">
          <section className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h1 className="mb-2 text-uppercase">A FAMILY DREAM</h1>
              <p className="mb-2">We insure the future of corporate business</p>
              <h1 className="text-uppercase">purpose of life</h1>
            </div>
          </section>
          <section className="president d-flex flex-wrap justify-content-between align-items-center">
            <div className="presidentWord">
              <h2 className="pink-color text-uppercase fw-bolder mb-0">when</h2>
              <h2 className="pink-color text-uppercase fw-bolder mb-3">
                it all started
              </h2>
              <p className="fw-medium">
                "GPCO was established in 2013 as a dream of{" "}
                <span className="fw-bold">Mr. Nawaf</span> who sought to
                preserve his heritage. Gradually, he began to acquire the
                necessary licenses and supervision from the Central Bank of
                Saudi Arabia, allowing people to place their trust in him and
                contribute to the development of a more robust company. Over the
                years, he has built a strong team, and they have become what
                they are today."
              </p>
            </div>
            <div className="presidentImage rounded rounded-5">
              <img src={presindentImage} alt="" />
            </div>
          </section>
          <section className="workers d-flex flex-wrap justify-content-between align-items-center text-start">
            <div className="workersImage rounded rounded-5">
              <img src={workersImage} alt="" />
            </div>
            <div className="workersWord">
              <p className="fw-bold mb-2">
                Today, we are a business group with more than 50 members focused
                on the same objective:
              </p>
              <h2 className="pink-color text-uppercase fw-bolder mb-0 fs-3">
                Providing protection and security for your future.
              </h2>
            </div>
          </section>
          <section className="purpose d-flex justify-content-center align-items-center flex-column rounded rounded-3 overflow-hidden text-white">
            <div className="purposeContent text-center">
              <h2 className="fw-bolder text-uppercase">Our purpose</h2>
              <p className="w-75 fs-5 text-center m-auto fw-medium">
                Enhance companies' well-being and ensure their future
                protection.
              </p>
            </div>
          </section>
          <CHGrid />
          <section className="differ d-flex flex-wrap justify-content-between align-items-center">
            <div className="differWord">
              <h2 className="pink-color text-uppercase fw-bolder mb-3">
                we differ because...
              </h2>
              <ul>
                <li>
                  <p className="my-0 fw-medium">
                    We are looking for the best alternative to fit your needs
                    and ensure the most appropriate protection for your company.
                  </p>
                </li>
                <li>
                  <p className="my-0 fw-medium">
                    We provide advisory services and risk analysis to identify
                    various alternatives to these transfers, including but not
                    limited to; Captive companies and insurance contracts.
                  </p>
                </li>
                <li>
                  <p className="my-0 fw-medium">
                    We offer you a complete program in managing and managing
                    your risks and the risks of your company.
                  </p>
                </li>
              </ul>
            </div>
            <div className="differImage rounded rounded-5">
              <img src={presindentImage} alt="" />
            </div>
          </section>
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-4">
              we do things well
            </h2>
            <div className="doingList d-flex ">
              <p>We think about you</p>
              <p>We generate value for you</p>
              <p>We protect you</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contact"
            >
              Contact Us
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
