import React from "react";
import colorfulStars from "../../assets/images/Pages/Home/colorfulStars.svg";
import quotes from "../../assets/images/Pages/Home/quote.svg";

export default function FeedbackAr({ comment, pathToImage, name }) {
  return (
    <div className="opinion-box px-3 pt-5 pb-4 bg-white shadow-sm position-relative text-end">
      <div className="quotes position-absolute end-0">
        <img src={quotes} alt="" />
      </div>
      <div className="stars mb-3">
        <img src={colorfulStars} alt="" />
      </div>
      <p className="opinion" dir="rtl">
        {comment}
      </p>
      <div className="personal-image-name d-flex align-items-center justify-content-start flex-row-reverse w-100 pt-3 gap-3">
        <div className="personal-image">
          <img src={pathToImage} alt="sss" />
        </div>
        <div className="personal-name mb-0">
          <p className="mb-0">{name}</p>
        </div>
      </div>
    </div>
  );
}
