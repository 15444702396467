import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import CHGrid from "../../Common/CHGrid";
import serviceimageblock1 from "../../../assets/images/Pages/Services/corporate/images/block1.jpg";
import serviceimageblock2 from "../../../assets/images/Pages/Services/corporate/images/block2.jpg";
import serviceimageblock3 from "../../../assets/images/Pages/Services/corporate/images/block3.jpg";
import serviceimageblock4 from "../../../assets/images/Pages/Services/corporate/images/block4.webp";

export default function Corporate() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="corporateServicesPage">
          <section className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <p className="mb-2">We are to search</p>
              <h1 className="text-uppercase">WHAT YOU NEED</h1>
            </div>
          </section>
          <section className="service-discription w-100 d-flex align-items mx-auto-center flex-column">
            <div className="service-discription-content mx-auto">
              <p className="fw-medium mb-2">
                Companies like people experience constantly evolving risks.
                <span className="fw-bold mx-2">
                  Insurance is the best way to protect your interests and ensure
                  that everything will continue to work, even if there is an
                  unforeseen event.
                </span>
              </p>
            </div>
          </section>
          <section className="service-block service-block1 d-flex justify-content-between align-items-center flex-wrap">
            <ul className="nav-list service-block-list d-flex flex-column gap-3 fw-bold">
              <li className="nav-item">Group Life</li>
              <li className="nav-item">Debtor life</li>
              <li className="nav-item">Automobile Collective</li>
              <li className="nav-item">Transport</li>
              <li className="nav-item">Transport of valuables</li>
              <li className="nav-item">Compliance</li>
              <li className="nav-item">Commercial Management</li>
            </ul>
            <div className="service-block-image rounded rounded-4">
              <img src={serviceimageblock1} className="img-fluid" alt="" />
            </div>
          </section>
          <section className="service-block service-block2 d-flex justify-content-between align-items-center flex-wrap">
            <div className="service-block-image rounded rounded-4">
              <img src={serviceimageblock2} className="img-fluid" alt="" />
            </div>
            <ul className="nav-list service-block-list d-flex flex-column gap-3 fw-bold ">
              <li className="nav-item">All Risk Construction</li>
              <li className="nav-item">All Risk Material Damage</li>
              <li className="nav-item">All Risk Assembly</li>
              <li className="nav-item">Civil Liability</li>
              <li className="nav-item">Decennial</li>
              <li className="nav-item">Co-ownership</li>
            </ul>
          </section>
          <section className="service-block service-block3 d-flex justify-content-between align-items-center flex-wrap">
            <ul className="nav-list service-block-list d-flex flex-column gap-3 fw-bold">
              <li className="nav-item">Mobile machines</li>
              <li className="nav-item">Helmet and Aviation</li>
              <li className="nav-item">Yachts and Pleasure Boats </li>
              <li className="nav-item">Agricultural Insurance</li>
              <li className="nav-item">Credit insurance</li>
              <li className="nav-item">Financial Lines</li>
            </ul>
            <div className="service-block-image rounded rounded-4">
              <img src={serviceimageblock3} className="img-fluid" alt="" />
            </div>
          </section>
          <section className="service-block service-block2 d-flex justify-content-between align-items-center flex-wrap">
            <div className="service-block-image rounded rounded-4">
              <img src={serviceimageblock4} className="img-fluid" alt="" />
            </div>
            <ul className="nav-list service-block-list d-flex flex-column gap-3 fw-bold ">
              <li className="nav-item">R.C Directors and Administrators</li>
              <li className="nav-item">infidelity and Financial Risks</li>
              <li className="nav-item">Cyber Risk</li>
            </ul>
          </section>
          <CHGrid />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              we do things well
            </h2>
            <div className="doingList d-flex ">
              <p>We think about you</p>
              <p>We generate value for you</p>
              <p>We protect you</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contact"
            >
              Contact Us
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
