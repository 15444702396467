import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ColorfulLogo from "../../assets/images/ColorfulLogo.png";
import englishLanguageFlag from "../../assets/images/englishLanguageFlag.jpg";

export default function Navbar({ setFlag }) {
  const [listActive, setlistActive] = useState("list");
  const [navbarOverlayActive, setNavbarOverlayActive] =
    useState("menuNavOverlay");
  const showNav = () => {
    listActive === "list" && setlistActive("list list-active");
    navbarOverlayActive === "menuNavOverlay" &&
      setNavbarOverlayActive("menuNavOverlay active");
  };
  const hideNav = () => {
    listActive === "list list-active" && setlistActive("list");
    navbarOverlayActive === "menuNavOverlay active" &&
      setNavbarOverlayActive("menuNavOverlay");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="custom-container d-flex justify-content-between align-items-center w-100">
          <div className="brand__Language d-flex gap-3">
            <Link className="navbar-brand p-0" to="/homeAr">
              <img src={ColorfulLogo} className="logo" alt="GPCO-Logo" />
            </Link>
            <NavLink
              className="nav-link d-flex gap-2 flex-row-reverse me-auto align-items-center"
              aria-current="page"
              onClick={() => {
                setFlag("EN");
              }}
              to={"/"}
            >
              <img
                src={englishLanguageFlag}
                className="englishLanguageFlag"
                alt="Arabic Language"
              />
              <span className="language">الانجليزية</span>
            </NavLink>
          </div>
          <div className={listActive}>
            <div onClick={hideNav} className="close">
              <div className="line1"></div>
              <div className="line2"></div>
            </div>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 fw-bold">
              <li className="nav-item">
                <NavLink className="nav-link" onClick={hideNav} to="/homeAr">
                  الرئيسية
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={hideNav} to="/aboutAr">
                  من نحن
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={hideNav} to="servicesAr">
                  خدماتنا
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={hideNav} to="blogsAr">
                  المقالات
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={hideNav} to="contactAr">
                  تواصل معنا
                </NavLink>
              </li>
            </ul>
          </div>
          <div onClick={showNav} className="burger gap-1 flex-column">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>
        <div className={navbarOverlayActive}></div>
      </nav>
    </>
  );
}
