import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import benefitsImage from "../../../assets/images/Pages/Services/managments/images/benefits.jpg";
import CHGrid from "../../Common/CHGrid";

export default function Management() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="managementServicesPage">
          <section className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden">
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h2 className="fw-bolder mb-0">
                Permanence and sustainability over time
              </h2>
              <p className="mb-3 fs-4">
                of any business depends on an adequate
              </p>
              <h1 className="text-uppercase">risk management</h1>
            </div>
          </section>
          <section className="service-discription w-100 d-flex align-items mx-auto-center flex-column">
            <div className="service-discription-content mx-auto">
              <p className="fw-medium mb-2">
                Our focus is
                <span className="fw-bold mx-2">
                  identify, evaluate and manage
                </span>
                each risk in a global manner and for this, we take the
                organization's objectives as a basis, in order to structure a
                value creation process that responsibly guarantees compliance
                with these.
              </p>
            </div>
          </section>
          <section className="management-block">
            <div className="management-block-title">
              <h2 className="fw-bolder text-uppercase text-center pink-color mb-4">
                benefits
              </h2>
            </div>
            <div className="management-block-body d-flex justify-content-between align-items-center flex-wrap">
              <div className="management-block-text">
                <ul className="management-list nav-list fw-medium">
                  <li className="nav-item">
                    <p>Reducing financial risks and stabilizing the company</p>
                  </li>
                  <li className="nav-item">
                    <p>
                      Preparing the company to deal with crises and disasters,
                      which reduces their negative impact
                    </p>
                  </li>
                  <li className="nav-item">
                    <p>
                      Improving its operations and increasing its operational
                      efficiency. If areas of weakness are identified, they can
                      be improved to achieve maximum benefit
                    </p>
                  </li>
                  <li className="nav-item">
                    <p>
                      Determine the risks that can be covered by insurance, and
                      save unnecessary insurance costs
                    </p>
                  </li>
                </ul>
              </div>
              <div className="management-block-image">
                <img src={benefitsImage} alt="" className="rounded rounded-4" />
              </div>
            </div>
          </section>
          <section className="note-block">
            <h2 className="fs-1 text-uppercase fw-bolder pink-color text-center mb-3">
              IT SHOULD BE NOTED THAT
            </h2>
            <p className="mx-auto text-center fw-medium">
              estas alternativas no son un sustituto para el mercado de los
              seguros y reaseguros, sino un complemento de estas, y algunos de
              estos vehículos son:
            </p>
          </section>
          <section className="management-grid ">
            <div className="management-grid-columns d-flex text-white text-start">
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>SOCIEDADES CAUTIVAS</p>
              </div>
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>
                  GRUPOS DE RETENCION DE RIESGOS
                  <span className="fw-normal fs-6 mx-1">
                    “SOCIEDADES MUTUAS”
                  </span>
                </p>
              </div>
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>
                  INSTRUMENTOS DERIVADOS
                  <span className="fw-normal fs-6 mx-1">
                    “FORWARDS, FUTUROS, OPCIONES Y SWAPS”
                  </span>
                </p>
              </div>
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>TITULACIONES DE RIESGOS “ILS”</p>
              </div>
              <div className="management-grid-column p-4 rounded rounded-4">
                <p>INTRUMENTOS DE CAPITAL DE CONTINGENCIA</p>
              </div>
            </div>
          </section>
          <section className="management-discription">
            <p className="fw-medium fs-5">
              At GPCO we have used this risk transfer vehicle for more than 10
              years, which consists of the formation of a company whose purpose
              is to offer insurance or reinsurance coverage, exclusively for the
              risks of the company or companies to which it belongs or for the
              group of which he is a part.
            </p>
          </section>
          <CHGrid />
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              we do things well
            </h2>
            <div className="doingList d-flex ">
              <p>We think about you</p>
              <p>We generate value for you</p>
              <p>We protect you</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contact"
            >
              Contact Us
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
