import React from "react";
import { NavLink } from "react-router-dom";
import WhiteLogo from "../../assets/images/WhiteLogo.png";

export default function Footer() {
  return (
    <footer className="bg-gradient-darkBlue">
      <div className="custom-container">
        <div className="footerContent pt-5 pb-4 d-flex text-white flex-wrap">
          <div className="footerColumn d-flex flex-column">
            <div className="footerColumnTitle mb-3">
              <img src={WhiteLogo} className="logo mb-2" alt="" />
            </div>
            <p className="companyDescription">
              We are an insurance agency with more than 10 years of experience,
              which provides advice and solutions for risk management and
              insurance through products designed to suit your needs.
            </p>
          </div>
          <div className="footerColumn d-flex flex-column">
            <div className="footerColumnTitle mb-3">
              <h3>Main Menu</h3>
            </div>
            <ul>
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="about">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="services">
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="blogs">
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="contact">
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="footerColumn d-flex flex-column">
            <div className="footerColumnTitle mb-3">
              <h3>GPCO Services</h3>
            </div>
            <ul>
              <li className="nav-item">
                <NavLink className="nav-link" to="services/corporate">
                  Corporate insurance
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="services/occupational">
                  Occupational hazards
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="services/management">
                  Advice on risk mamanagement
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="services/claims">
                  davice on claims
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="footerColumn d-flex flex-column">
            <div className="footerColumnTitle mb-3">
              <h3>CONTACT US</h3>
            </div>
            <ul>
              <li className="nav-item">
                <a
                  className="nav-link d-flex gap-3 align-items-start"
                  href="mailto:info@gpco.com.sa"
                >
                  <i className="fa-regular fa-envelope position-relative pt-1"></i>
                  <span>info@gpco.com.sa</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link d-flex gap-3 align-items-start"
                  href="tel:+966112925599"
                >
                  <i className="fa-solid fa-phone position-relative pt-1"></i>
                  <span>+966 112925599</span>
                </a>
              </li>
              <li className="nav-item">
                <p className="nav-link d-flex gap-3 align-items-start">
                  <i className="fa-solid fa-location-dot position-relative pt-1"></i>
                  <span>
                    Al Umam Commercial Center 3rd Floor Unit : 331 Salah Ad Din
                    Al Ayyubi Road P.O. Box 27663 Riyadh 11427 Kingdom of Saudi
                    Arabia
                  </span>
                </p>
              </li>
            </ul>
            <div className="footersocial  mt-2 d-flex gap-3">
              <a
                href="https://www.facebook.com/GpcoSa"
                rel="noreferrer"
                target="_blank"
                className="pink-color"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/gpcosa/"
                rel="noreferrer"
                target="_blank"
                className="pink-color"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/gpcosa/"
                rel="noreferrer"
                target="_blank"
                className="pink-color"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
              <a
                href="https://twitter.com/GpcoSa"
                rel="noreferrer"
                target="_blank"
                className="pink-color"
              >
                <i className="fa-brands fa-x-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="footerRights py-4 text-white">
          <p className="m-0">All Rights Are Reserved</p>
          <p className="m-0">
            <i className="fa-regular fa-copyright"></i> GPCO 2023
          </p>
        </div>
      </div>
    </footer>
  );
}
