import React, { useEffect } from "react";
import presindentImage from "../../assets/images/Pages/About/presindentImage.jpeg";
import workersImage from "../../assets/images/Pages/About/workersImage.jpg";
import { NavLink } from "react-router-dom";
import CHGridAr from "../Common/CHGridAr";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="custom-container">
        <div className="aboutPage">
          <section
            className="hero mt-5 rounded rounded-5 position-relative w-100 overflow-hidden"
            dir="rtl"
          >
            <div className="heroContent position-absolute text-center start-50 bottom-0 pb-5 translate-middle-x w-100">
              <h1 className="mb-2 text-uppercase">حلم عائلي</h1>
              <p className="mb-2">نحن نؤمن مستقبل أعمال الشركات</p>
              <h1 className="text-uppercase">الغرض من الحياة</h1>
            </div>
          </section>
          <section
            className="president d-flex flex-wrap justify-content-between align-items-center"
            dir="rtl"
          >
            <div className="presidentWord">
              <h2 className="pink-color text-uppercase fw-bolder mb-0">
                عندما
              </h2>
              <h2 className="pink-color text-uppercase fw-bolder mb-3">
                بدأ كل شيء
              </h2>
              <p className="fw-medium">
                "تأسست شركة جيبكو في عام 2013 كحلم
                <span className="fw-bold">السيد نواف</span> الذي سعى إلى الحفاظ
                على تراثه. تدريجيا، بدأ في الحصول على التراخيص اللازمة والإشراف
                من البنك المركزي المملكة العربية السعودية، مما يسمح للناس بوضع
                ثقتهم فيه و المساهمة في تطوير شركة أكثر قوة. على مدار سنوات، قام
                ببناء فريق قوي، وقد أصبحوا ما هم اليوم."
              </p>
            </div>
            <div className="presidentImage rounded rounded-5">
              <img src={presindentImage} alt="" />
            </div>
          </section>
          <section
            className="workers d-flex flex-wrap justify-content-between align-items-center text-end"
            dir="rtl"
          >
            <div className="workersImage rounded rounded-5">
              <img src={workersImage} alt="" />
            </div>
            <div className="workersWord">
              <p className="fw-bold mb-2">
                اليوم، نحن مجموعة أعمال تضم أكثر من 50 عضوًا على نفس الهدف:
              </p>
              <h2 className="pink-color text-uppercase fw-bolder mb-0 fs-3">
                توفير الحماية والأمان لمستقبلك.
              </h2>
            </div>
          </section>
          <section className="purpose d-flex justify-content-center align-items-center flex-column rounded rounded-3 overflow-hidden text-white">
            <div className="purposeContent text-center">
              <h2 className="fw-bolder text-uppercase">غرضنا</h2>
              <p className="w-75 fs-5 text-center m-auto fw-medium">
                تعزيز رفاهية الشركات وضمان مستقبلها حماية.
              </p>
            </div>
          </section>
          <CHGridAr />
          <section
            className="differ d-flex flex-wrap justify-content-between align-items-center"
            dir="rtl"
          >
            <div className="differWord">
              <h2 className="pink-color text-uppercase fw-bolder mb-3">
                نختلف لأن...
              </h2>
              <ul>
                <li>
                  <p className="my-0 fw-medium">
                    نحن نبحث عن البديل الأفضل الذي يناسب احتياجاتك وضمان الحماية
                    الأنسب لشركتك.
                  </p>
                </li>
                <li>
                  <p className="my-0 fw-medium">
                    نحن نقدم الخدمات الاستشارية وتحليل المخاطر لتحديد البدائل
                    المختلفة لهذه التحويلات، بما في ذلك ولكن لا تقتصر على؛
                    الشركات الأسيرة وعقود التأمين.
                  </p>
                </li>
                <li>
                  <p className="my-0 fw-medium">
                    نقدم لك برنامج متكامل في الإدارة والإدارة مخاطرك ومخاطر
                    شركتك.
                  </p>
                </li>
              </ul>
            </div>
            <div className="differImage rounded rounded-5">
              <img src={presindentImage} alt="" />
            </div>
          </section>
          <section className="weDo w-75 text-white mx-auto d-flex flex-column align-items-center rounded rounded-4 p-4">
            <h2 className="text-uppercase text-center mb-2">
              نحن نفعل الأشياء بشكل جيد
            </h2>
            <div className="doingList d-flex ">
              <p>نحن نفكر فيك</p>
              <p>نحن نولد قيمة بالنسبة لك</p>
              <p>نحن نحميك</p>
            </div>
            <NavLink
              className="nav-link d-inline-block mt-2 px-3 py-2 rounded rounded-3 pink-Nav-link"
              to="/contactAr"
            >
              اتصل بنا
            </NavLink>
          </section>
        </div>
      </div>
    </>
  );
}
