import React from "react";
import { NavLink } from "react-router-dom";
import WhiteLogo from "../../assets/images/WhiteLogo.png";

export default function Footer() {
  return (
    <footer className="bg-gradient-darkBlue" dir="rtl">
      <div className="custom-container">
        <div className="footerContent pt-5 pb-4 d-flex text-white flex-wrap">
          <div className="footerColumn d-flex flex-column">
            <div className="footerColumnTitle mb-3">
              <img src={WhiteLogo} className="logo mb-2" alt="" />
            </div>
            <p className="companyDescription">
              نحن وكالة تأمين مع أكثر من 10 سنوات من الخبرة، والتي تقدم المشورة
              والحلول للمخاطر الإدارة والتأمين من خلال المنتجات المصممة لتناسب
              احتياجاتك الاحتياجات.
            </p>
          </div>
          <div className="footerColumn d-flex flex-column">
            <div className="footerColumnTitle mb-3">
              <h3>القائمة الرئيسية</h3>
            </div>
            <ul>
              <li className="nav-item">
                <NavLink className="nav-link" to="/homeAr">
                  الرئيسية
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="aboutAr">
                  من نحن
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="servicesAr">
                  خدماتنا
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="blogsAr">
                  المدونات
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="contactAr">
                  تواصل معنا
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="footerColumn d-flex flex-column">
            <div className="footerColumnTitle mb-3">
              <h3>خدمات GPCO</h3>
            </div>
            <ul>
              <li className="nav-item">
                <NavLink className="nav-link" to="servicesAr/corporateAr">
                  تأمين الشركات
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="servicesAr/occupationalAr">
                  المخاطر المهنية
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="servicesAr/managementAr">
                  إدارة المخاطر
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="servicesAr/claimsAr">
                  إدارة المطالبات
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="footerColumn d-flex flex-column">
            <div className="footerColumnTitle mb-3">
              <h3>تواصل معنا</h3>
            </div>
            <ul>
              <li className="nav-item">
                <a
                  className="nav-link d-flex gap-3 align-items-start"
                  href="mailto:info@gpco.com.sa"
                >
                  <i className="fa-regular fa-envelope position-relative pt-1"></i>
                  <span>info@gpco.com.sa</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link d-flex gap-3 align-items-start"
                  href="tel:+966112925599"
                >
                  <i className="fa-solid fa-phone position-relative pt-1"></i>
                  <span dir="ltr">+966 112925599</span>
                </a>
              </li>
              <li className="nav-item">
                <p className="nav-link d-flex gap-3 align-items-start">
                  <i className="fa-solid fa-location-dot position-relative pt-1"></i>
                  <span>
                    مركز الأمم التجاري الطابق الثالث الوحدة : 331 صلاح الدين
                    طريق الأيوبي ص.ب. ص.ب 27663 الرياض 11427 المملكة السعودية
                    الجزيرة العربية
                  </span>
                </p>
              </li>
            </ul>
            <div className="footersocial  mt-2 d-flex gap-3">
              <a
                href="https://www.facebook.com/GpcoSa"
                rel="noreferrer"
                target="_blank"
                className="pink-color"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/gpcosa/"
                rel="noreferrer"
                target="_blank"
                className="pink-color"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/gpcosa/"
                rel="noreferrer"
                target="_blank"
                className="pink-color"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
              <a
                href="https://twitter.com/GpcoSa"
                rel="noreferrer"
                target="_blank"
                className="pink-color"
              >
                <i className="fa-brands fa-x-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="footerRights py-4 text-white">
          <p className="m-0">جميع الحقوق محفوظة</p>
          <p className="m-0">
            <i className="fa-regular fa-copyright"></i> GPCO 2023
          </p>
        </div>
      </div>
    </footer>
  );
}
